import React from 'react';
import { Helmet } from 'react-helmet';

const Legal = () => {
    return (
        <div className="legal-notice-container">
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <h3>AVISO LEGAL</h3>
            
            
            <p><strong><strong>LEY DE LOS SERVICIOS DE LA SOCIEDAD DE LA INFORMACI&Oacute;N&nbsp;(LSSI)</strong></strong></p>
            
            
            <p >Preciosisimo.com responsable del sitio web, en adelante RESPONSABLE, pone a disposici&oacute;n de los usuarios el presente documento, con el que pretende dar cumplimiento a las obligaciones dispuestas en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico (LSSICE), as&iacute; como informar a todos los usuarios del sitio web respecto a cu&aacute;les son las condiciones de uso.</p>
            
            
            <p >Toda persona que acceda a este sitio web asume el papel de usuario, comprometi&eacute;ndose a la observancia y cumplimiento riguroso de las disposiciones aqu&iacute; dispuestas, as&iacute; como a cualquier otra disposici&oacute;n legal que fuera de aplicaci&oacute;n.&nbsp;</p>
            
            
            <p >El responsable se reserva el derecho de modificar cualquier tipo de informaci&oacute;n que pudiera aparecer en el sitio web, sin que exista obligaci&oacute;n de preavisar o poner en conocimiento de los usuarios dichas obligaciones, entendi&eacute;ndose como suficiente con la publicaci&oacute;n en el sitio web</p>
            
            
            <p ><strong><strong>1. DATOS IDENTIFICATIVOS</strong></strong></p>
            
            
            <p>Denominación: Preciosisimo.com</p>
            <p>e-mail: info@preciosisimo.com</p>
            
            
            <p ><strong><strong>2. OBJETO</strong></strong></p>
            
            
            <p >A trav&eacute;s del Sitio Web, les ofrecemos a los Usuarios la posibilidad de acceder a la informaci&oacute;n sobre nuestros servicios.</p>
            
            
            <p ><strong><strong>3. PRIVACIDAD Y TRATAMIENTO DE DATOS</strong></strong></p>
            
            
            <p >Cuando para el acceso a determinados contenidos o servicio sea necesario facilitar datos de car&aacute;cter personal, los Usuarios garantizar&aacute;n su veracidad, exactitud, autenticidad y vigencia. La empresa dar&aacute; a dichos datos el tratamiento automatizado que corresponda en funci&oacute;n de su naturaleza o finalidad, en los t&eacute;rminos indicados en la secci&oacute;n de Pol&iacute;tica de Privacidad.</p>
            <p ><strong><strong>4. PROPIEDAD INDUSTRIAL E INTELECTUAL</strong></strong></p>
            
            
            <p >El Usuario reconoce y acepta que todos los contenidos que se muestran en el Espacio Web y en especial, dise&ntilde;os, textos, im&aacute;genes, logos, iconos, botones, software, nombres comerciales, marcas, o cualesquiera otros signos susceptibles de utilizaci&oacute;n industrial y/o comercial est&aacute;n sujetos a derechos de Propiedad Intelectual y todas las marcas, nombres comerciales o signos distintivos, todos los derechos de propiedad industrial e intelectual, sobre los contenidos y/o cualesquiera otros elementos insertados en el p&aacute;gina, que son propiedad exclusiva de la empresa y/o de terceros, quienes tienen el derecho exclusivo de utilizarlos en el tr&aacute;fico econ&oacute;mico. Por todo ello el Usuario se compromete a no reproducir, copiar, distribuir, poner a disposici&oacute;n o de cualquier otra forma comunicar p&uacute;blicamente, transformar o modificar tales contenidos manteniendo indemne a la empresa de cualquier reclamaci&oacute;n que se derive del incumplimiento de tales obligaciones. En ning&uacute;n caso el acceso al Espacio Web implica ning&uacute;n tipo de renuncia, transmisi&oacute;n, licencia o cesi&oacute;n total ni parcial de dichos derechos, salvo que se establezca expresamente lo contrario. Las presentes Condiciones Generales de Uso del Espacio Web no confieren a los Usuarios ning&uacute;n otro derecho de utilizaci&oacute;n, RRHH, alteraci&oacute;n, explotaci&oacute;n, reproducci&oacute;n, distribuci&oacute;n o comunicaci&oacute;n p&uacute;blica del Espacio Web y/o de sus Contenidos distintos de los aqu&iacute; expresamente previstos. Cualquier otro uso o explotaci&oacute;n de cualesquiera derechos estar&aacute; sujeto a la previa y expresa autorizaci&oacute;n espec&iacute;ficamente otorgada a tal efecto por la empresa o el tercero titular de los derechos afectados.</p>
            
            
            <p >Los contenidos, textos, fotograf&iacute;as, dise&ntilde;os, logotipos, im&aacute;genes, programas de ordenador, c&oacute;digos fuente y, en general, cualquier creaci&oacute;n intelectual existente en este Espacio, as&iacute; como el propio Espacio en su conjunto, como obra art&iacute;stica multimedia, est&aacute;n protegidos como derechos de autor por la legislaci&oacute;n en materia de propiedad intelectual. La empresa es titular de los elementos que integran el dise&ntilde;o gr&aacute;fico del Espacio Web, lo men&uacute;s, botones de navegaci&oacute;n, el c&oacute;digo HTML, los textos, im&aacute;genes, texturas, gr&aacute;ficos y cualquier otro contenido del Espacio Web o, en cualquier caso dispone de la correspondiente autorizaci&oacute;n para la utilizaci&oacute;n de dichos elementos. El contenido dispuesto en el Espacio Web no podr&aacute; ser reproducido ni en todo ni en parte, ni transmitido, ni registrado por ning&uacute;n sistema de recuperaci&oacute;n de informaci&oacute;n, en ninguna forma ni en ning&uacute;n medio, a menos que se cuente con la autorizaci&oacute;n previa, por escrito, de la citada Entidad.</p>
            
            
            <p >Asimismo queda prohibido suprimir, eludir y/o manipular el &laquo;copyright&raquo; as&iacute; como los dispositivos t&eacute;cnicos de protecci&oacute;n, o cualesquiera mecanismos de informaci&oacute;n que pudieren contener los contenidos. El Usuario de este Espacio Web se compromete a respetar los derechos enunciados y a evitar cualquier actuaci&oacute;n que pudiera perjudicarlos, reserv&aacute;ndose en todo caso la empresa el ejercicio de cuantos medios o acciones legales le correspondan en defensa de sus leg&iacute;timos derechos de propiedad intelectual e industrial.</p>
            
            
            <p ><strong><strong>5. OBLIGACIONES Y RESPONSABILIDADES DEL USUARIO DEL ESPACIO WEB</strong></strong></p>
            
            
            <p >El Usuario se compromete a:</p>
            
            
            <ol >
                <li >Hacer un uso adecuado y l&iacute;cito del Espacio Web as&iacute; como de los contenidos y servicios, de &nbsp;conformidad con: (i) la legislaci&oacute;n aplicable en cada momento; (ii) las Condiciones Generales de Uso del Espacio Web; (iii) la moral y buenas costumbres generalmente aceptadas y (iv) el orden p&uacute;blico.</li>
                <li >Proveerse de todos los medios y requerimientos t&eacute;cnicos que se precisen para acceder al Espacio Web.</li>
                <li >Facilitar informaci&oacute;n veraz al cumplimentar con sus datos de car&aacute;cter personal los formularios contenidos en el Espacio Web y a mantenerlos actualizados en todo momento de forma que responda, en cada momento, a la situaci&oacute;n real del Usuario. El Usuario ser&aacute; el &uacute;nico responsable de las manifestaciones falsas o inexactas que realice y de los perjuicios que cause a la empresa o a terceros por la informaci&oacute;n que facilite.</li>
            </ol>
            
            
            <p >No obstante lo establecido en el apartado anterior el Usuario deber&aacute; asimismo abstenerse de:</p>
            
            
            <ol >
                <li >Hacer un uso no autorizado o fraudulento del Espacio Web y/o de los contenidos con fines o efectos il&iacute;citos, prohibidos en las presentes Condiciones Generales de Uso, lesivos de los derechos e intereses de terceros, o que de cualquier forma puedan da&ntilde;ar, inutilizar, sobrecargar, deteriorar o impedir la normal utilizaci&oacute;n de los servicios o los documentos, archivos y toda clase de contenidos almacenados en cualquier equipo inform&aacute;tico.</li>
                <li >Acceder o intentar acceder a recursos o &aacute;reas restringidas del Espacio Web, sin cumplir las condiciones exigidas para dicho acceso.</li>
                <li >Provocar da&ntilde;os en los sistemas f&iacute;sicos o l&oacute;gicos del Espacio Web, de sus proveedores o de terceros.</li>
                <li >Introducir o difundir en la red virus inform&aacute;ticos o cualesquiera otros sistemas f&iacute;sicos o l&oacute;gicos que sean susceptibles de provocar da&ntilde;os en los sistemas f&iacute;sicos o l&oacute;gicos de la empresa, proveedores o de terceros.</li>
                <li >Intentar acceder, utilizar y/o manipular los datos de la empresa, terceros proveedores y otros Usuarios.</li>
                <li >Reproducir o copiar, distribuir, permitir el acceso del p&uacute;blico a trav&eacute;s de cualquier modalidad de comunicaci&oacute;n p&uacute;blica, transformar o modificar los contenidos, a menos que se cuente con la autorizaci&oacute;n del titular de los correspondientes derechos o ello resulte legalmente permitido.</li>
                <li >Suprimir, ocultar o manipular las notas sobre derechos de propiedad intelectual o industrial y dem&aacute;s datos identificativos de los derechos de la empresa o de terceros incorporados a los contenidos, as&iacute; como los dispositivos t&eacute;cnicos de protecci&oacute;n o cualesquiera mecanismos de informaci&oacute;n que puedan insertarse en los contenidos.</li>
                <li >Obtener e intentar obtener los contenidos empleando para ello medios o procedimientos distintos de los que, seg&uacute;n los casos, se hayan puesto a su disposici&oacute;n a este efecto o se hayan indicado expresamente en las p&aacute;ginas web donde se encuentren los contenidos o, en general, de los que se empleen habitualmente en Internet por no entra&ntilde;ar un riesgo de da&ntilde;o o inutilizaci&oacute;n del Espacio web y/o de los contenidos.</li>
                <li >En particular, y a t&iacute;tulo meramente indicativo y no exhaustivo, el Usuario se compromete a no transmitir, difundir o poner a disposici&oacute;n de terceros informaciones, datos, contenidos, mensajes, gr&aacute;ficos, dibujos, archivos de sonido y/o imagen, fotograf&iacute;as, grabaciones, software y, en general, cualquier clase de material que: &bull; De cualquier forma sea contrario, menosprecie o atente contra los derechos fundamentales y las libertades p&uacute;blicas reconocidas constitucionalmente, en los Tratados Internacionales y en el resto de la legislaci&oacute;n vigente.&bull; Induzca, incite o promueva actuaciones delictivas, denigratorias, difamatorias, violentas o, en general, contrarias a la ley, a la moral, a las buenas costumbres generalmente aceptadas o al orden p&uacute;blico.&bull; Induzca, incite o promueva actuaciones, actitudes o pensamientos discriminatorios por raz&oacute;n de sexo, raza, religi&oacute;n, creencias, edad o condici&oacute;n.&bull; Incorpore, ponga a disposici&oacute;n o permita acceder a productos, elementos, mensajes y/o servicios delictivos, violentos, ofensivos, nocivos, degradantes o, en general, contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden p&uacute;blico. Induzca o pueda inducir a un estado inaceptable de ansiedad o temor.&bull; Induzca o incite a involucrarse en pr&aacute;cticas peligrosas, de riesgo o nocivas para la salud y el equilibrio ps&iacute;quico.&bull; Se encuentra protegido por la legislaci&oacute;n en materia de protecci&oacute;n intelectual o industrial perteneciente a la sociedad o a terceros sin que haya sido autorizado el uso que se pretenda realizar.&bull; Sea contrario al honor, a la intimidad personal y familiar o a la propia imagen de las personas.&bull; Constituya cualquier tipo de publicidad.&bull; Incluya cualquier tipo de virus o programa que impida el normal funcionamiento del Espacio Web.</li>
            </ol>
            
            
            <p >Si para acceder a algunos de los servicios y/o contenidos del Espacio Web, se le proporcionara una contrase&ntilde;a, se obliga a usarla de manera diligente, manteni&eacute;ndola en todo momento en secreto. En consecuencia, ser&aacute; responsable de su adecuada custodia y confidencialidad, comprometi&eacute;ndose a no cederla a terceros, de manera temporal o permanente, ni a permitir el acceso a los mencionados servicios y/o contenidos por parte de personas ajenas. Igualmente, se obliga a notificar a la sociedad cualquier hecho que pueda suponer un uso indebido de su contrase&ntilde;a, como, a t&iacute;tulo enunciativo, su robo, extrav&iacute;o o el acceso no autorizado, con el fin de proceder a su inmediata cancelaci&oacute;n. En consecuencia, mientras no efect&uacute;e la notificaci&oacute;n anterior, la empresa quedar&aacute; eximida de cualquier responsabilidad que pudiera derivarse del uso indebido de su contrase&ntilde;a, siendo de su responsabilidad cualquier utilizaci&oacute;n il&iacute;cita de los contenidos y/o servicios del Espacio Web por cualquier tercero ileg&iacute;timo. Si de manera negligente o dolosa incumpliera cualquiera de las obligaciones establecidas en las presentes Condiciones Generales de Uso, responder&aacute; por todos los da&ntilde;os y perjuicios que de dicho incumplimiento pudieran derivarse para la empresa.</p>
            
            
            <p ><strong><strong>6. RESPONSABILIDADES</strong></strong></p>
            
            
            <p >No se garantiza el acceso continuado, ni la correcta visualizaci&oacute;n, descarga o utilidad &nbsp;de los elementos e informaciones contenidas en la web que puedan verse impedidos, dificultados o interrumpidos por factores o circunstancias que est&aacute;n fuera de su control. No se hace responsable de las decisiones que pudieran adoptarse como consecuencia del acceso a los contenidos o informaciones ofrecidas.</p>
            
            
            <p >Se podr&aacute; interrumpir el servicio, o resolver de modo inmediato la relaci&oacute;n con el Usuario, si se detecta que un uso de su Espacio Web, o de cualquiera de los servicios ofertados en el mismo, es contrario a las presentes Condiciones Generales de Uso. No nos hacemos responsables por da&ntilde;os, perjuicios, p&eacute;rdidas, reclamaciones o gastos derivados del uso del Espacio Web.</p>
            <p >&Uacute;nicamente ser&aacute; responsable de eliminar, lo antes posible, los contenidos que puedan generar tales perjuicios, siempre que as&iacute; se notifique. En especial no seremos responsables de los perjuicios que se pudieran derivar, entre otros, de:</p>
            
            
            <ol >
                <li >Interferencias, interrupciones, fallos, omisiones, aver&iacute;as telef&oacute;nicas, retrasos, bloqueos o desconexiones en el funcionamiento del sistema electr&oacute;nico, motivadas por deficiencias, sobrecargas y errores en las l&iacute;neas y redes de telecomunicaciones, o por cualquier otra causa ajena al control de la empresa.&nbsp;</li>
                <li >Intromisiones ileg&iacute;timas mediante el uso de programas malignos de cualquier tipo y a trav&eacute;s de cualquier medio de comunicaci&oacute;n, tales como virus inform&aacute;ticos o cualesquiera otros.</li>
                <li >Abuso indebido o inadecuado del Espacio Web.</li>
                <li >Errores de seguridad o navegaci&oacute;n producidos por un mal funcionamiento del navegador o por el uso de versiones no actualizadas del mismo. El administrador del espacio web se reservan el derecho de retirar, total o parcialmente, cualquier contenido o informaci&oacute;n presente en el Espacio Web.</li>
            </ol>
            
            
            <p >La empresa excluye cualquier responsabilidad por los da&ntilde;os y perjuicios de toda naturaleza que pudieran deberse a la mala utilizaci&oacute;n de los servicios de libre disposici&oacute;n y uso por parte de los Usuarios de Espacio Web. Asimismo queda exonerado de cualquier responsabilidad por el contenido e informaciones que puedan ser recibidas como consecuencia de los formularios de recogida de datos, estando los mismos &uacute;nicamente para la prestaci&oacute;n de los servicios de consultas y dudas. Por otro lado, en caso de causar da&ntilde;os y perjuicios por un uso il&iacute;cito o incorrecto de dichos servicios, podr&aacute; ser el Usuario reclamado por los da&ntilde;os o perjuicios causados.</p>
            <p >Usted mantendr&aacute; a la empresa indemne frente a cualesquiera da&ntilde;os y perjuicios que se deriven de reclamaciones, acciones o demandas de terceros como consecuencia de su acceso o uso del Espacio Web. Asimismo, usted se obliga a indemnizar frente a cualesquiera da&ntilde;os y perjuicios, que se deriven del uso por su parte de &ldquo;robots&rdquo;, &ldquo;spiders&rdquo;, &ldquo;crawlers&rdquo; o herramientas similares empleadas con el fin de recabar o extraer datos o de cualquier otra actuaci&oacute;n por su parte que imponga una carga irrazonable sobre el funcionamiento del Espacio Web.</p>
            
            
            <p ><strong><strong>7. HIPERV&Iacute;NCULOS</strong></strong></p>
            
            
            <p >El Usuario se obliga a no reproducir de ning&uacute;n modo, ni siquiera mediante un hiperenlace o hiperv&iacute;nculo, el Espacio Web, as&iacute; como ninguno de sus contenidos, salvo autorizaci&oacute;n expresa y por escrito del responsable del fichero.</p>
            
            
            <p >El Espacio Web puede incluir enlaces a otros espacios web, gestionados por terceros, con objeto de facilitar el acceso del Usuario a la informaci&oacute;n de empresas colaboradoras y/o patrocinadoras. Conforme con ello, la sociedad no se responsabiliza del contenido de dichos Espacios web, ni se sit&uacute;a en una posici&oacute;n de garante ni/o de parte ofertante de los servicios y/o informaci&oacute;n que se puedan ofrecer a terceros a trav&eacute;s de los enlaces de terceros.</p>
            
            
            <p >Se concede al Usuario un derecho limitado, revocable y no exclusivo a crear enlaces a la p&aacute;gina principal del Espacio Web exclusivamente para uso privado y no comercial. Los Espacios web que incluyan enlace a nuestro Espacio Web (i) no podr&aacute;n falsear su relaci&oacute;n ni afirmar que se ha autorizado tal enlace, ni incluir marcas, denominaciones, nombres comerciales, logotipos u otros signos distintivos de nuestra sociedad; (ii) no podr&aacute;n incluir contenidos que puedan considerarse de mal gusto, obscenos, ofensivos, controvertidos, que inciten a la violencia o la discriminaci&oacute;n por raz&oacute;n de sexo, raza o religi&oacute;n, contrarios al orden p&uacute;blico o il&iacute;citos; (iii) no podr&aacute;n enlazar a ninguna p&aacute;gina del Espacio Web distinta de la p&aacute;gina principal; (iv) deber&aacute; enlazar con la propia direcci&oacute;n del Espacio Web, sin permitir que el Espacio web que realice el enlace reproduzca el Espacio Web como parte de su web o dentro de uno de sus &ldquo;frames&rdquo; o crear un &ldquo;browser&rdquo; sobre cualquiera de las p&aacute;ginas del Espacio Web. La empresa podr&aacute; solicitar, en cualquier momento, que elimine cualquier enlace al Espacio Web, despu&eacute;s de lo cual deber&aacute; proceder de inmediato a su eliminaci&oacute;n.</p>
            
            
            <p >La empresa no puede controlar la informaci&oacute;n, contenidos, productos o servicios facilitados por otros Espacios web que tengan establecidos enlaces con destino al Espacio Web.</p>
            
            
            <p ><strong><strong>8. PROTECCI&Oacute;N DE DATOS</strong></strong></p>
            
            
            <p >Para utilizar algunos de los Servicios, el Usuario debe proporcionar previamente ciertos datos de car&aacute;cter personal. La empresa tratar&aacute; automatizadamente estos datos y aplicar&aacute; las correspondientes medidas de seguridad, todo ello en cumplimiento del RGPD, LOPDGDD y LSSI. El Usuario puede acceder a la pol&iacute;tica seguida en el tratamiento de los datos personales, as&iacute; como el establecimiento de las finalidades previamente establecidas, en las condiciones definidas en la Pol&iacute;tica de Privacidad.</p>
            
            
            <p ><strong><strong>9. COOKIES</strong></strong></p>
            
            
            <p >La empresa se reserva el derecho de utilizar la tecnolog&iacute;a &ldquo;cookie&rdquo; en el Espacio Web, a fin de reconocerlo como Usuario frecuente y personalizar el uso que realice del Espacio Web mediante la preselecci&oacute;n de su idioma, o contenidos m&aacute;s deseados o espec&iacute;ficos.</p>
            
            
            <p >Las cookies recopilan la direcci&oacute;n IP del usuario siendo Google el responsable del tratamiento de esta informaci&oacute;n.</p>
            
            
            <p >Las cookies son ficheros enviados a un navegador, por medio de un servidor Web, para registrar la navegaci&oacute;n del Usuario en el Espacio Web, cuando el Usuario permita su recepci&oacute;n. Si usted lo desea puede configurar su navegador para ser avisado en pantalla de la recepci&oacute;n de cookies y para impedir la instalaci&oacute;n de cookies en su disco duro. Por favor consulte las instrucciones y manuales de su navegador para ampliar esta informaci&oacute;n.</p>
            
            
            <p >Gracias a las cookies, resulta posible que se pueda reconocer el navegador del ordenador utilizado por el Usuario con la finalidad de facilitar contenidos y ofrecer las preferencias de navegaci&oacute;n u publicitarias que el Usuario, a los perfiles demogr&aacute;ficos de los Usuarios as&iacute; como para medir las visitas y par&aacute;metros del tr&aacute;fico, controlar el progreso y n&uacute;mero de entradas.</p>
            
            
            <p ><strong><strong>10. DECLARACIONES Y GARANT&Iacute;AS</strong></strong></p>
            
            
            <p >En general, los contenidos y servicios ofrecidos en el Espacio Web tienen car&aacute;cter meramente informativo. Por consiguiente, al ofrecerlos, no se otorga garant&iacute;a ni declaraci&oacute;n alguna en relaci&oacute;n con los contenidos y servicios ofrecidos en el Espacio web, incluyendo, a t&iacute;tulo enunciativo, garant&iacute;as de licitud, fiabilidad, utilidad, veracidad, exactitud, o comerciabilidad, salvo en la medida en que por ley no puedan excluirse tales declaraciones y garant&iacute;as.</p>
            
            
            <p ><strong><strong>11. FUERZA MAYOR</strong></strong></p>
            
            
            <p >La empresa no ser&aacute; responsable en todo en caso de imposibilidad de prestar servicio, si &eacute;sta se debe a interrupciones prolongadas del suministro el&eacute;ctrico, l&iacute;neas de telecomunicaciones, conflictos sociales, huelgas, rebeli&oacute;n, explosiones, inundaciones, actos y omisiones del Gobierno, y en general todos los supuestos de fuerza mayor o de caso fortuito.</p>
            
            
            <p ><strong><strong>12. RESOLUCI&Oacute;N DE CONTROVERSIAS. LEY APLICABLE Y JURISDICCI&Oacute;N</strong></strong></p>
            
            
            <p >Las presentes Condiciones Generales de Uso, as&iacute; como el uso del Espacio Web, se regir&aacute;n por la legislaci&oacute;n espa&ntilde;ola. Para la resoluci&oacute;n de cualquier controversia las partes se someter&aacute;n a los Juzgados y Tribunales del domicilio social del Responsable del sitio web.</p>
            
            
            <p >En el supuesto de que cualquier estipulaci&oacute;n de las presentes Condiciones Generales de Uso resultara inexigible o nula en virtud de la legislaci&oacute;n aplicable o como consecuencia de una resoluci&oacute;n judicial o administrativa, dicha inexigibilidad o nulidad no har&aacute; que las presentes Condiciones Generales de Uso resulten inexigibles o nulas en su conjunto. En dichos casos, la empresa proceder&aacute; a la modificaci&oacute;n o sustituci&oacute;n de dicha estipulaci&oacute;n por otra que sea v&aacute;lida y exigible y que, en la medida de lo posible, consiga el objetivo y pretensi&oacute;n reflejados en la estipulaci&oacute;n original.</p>
            <p ><strong><strong>13. PROGRAMAS DE AFILIACIÓN</strong></strong></p>
            <p>En calidad de Afiliado de Amazon, obtengo ingresos por las compras adscritas que cumplen los requisitos aplicables.</p>
        </div>
    );
}

export default Legal;